import './index.css';
import i18next from 'i18next';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import reportWebVitals from './reportWebVitals';
import global_en from './Translations/en/global';
import global_ru from './Translations/ru/global';
import global_ukr from './Translations/ukr/global';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'ukr',
  resources: {
    en: {
      global: global_en,
    },
    ru: {
      global: global_ru,
    },
    ukr: {
      global: global_ukr,
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
const App = React.lazy(() => import('./App'));

root.render(
  <React.StrictMode>
    <Suspense>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Suspense>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
